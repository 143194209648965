.prize-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #021713;
  padding: 0 1.4em;
  position: relative;
  overflow: hidden;
  // margin-bottom: -90px;
}

.prize-title-container {
  margin-top: 2em;
  position: relative;
  text-align: center;
  margin-bottom: 2em;
}

.prize-section-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 6.25em;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1.4px rgba(255, 255, 255, 0.15);
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -58%);
  z-index: 1;
  white-space: nowrap;
}

.prize-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 4em;
  color: #ffffff;
  z-index: 2;
  margin-bottom: 0;
}

.prize-category {
  width: 100%;
  text-align: center;
  margin-bottom: 3em;
}

.prize-category-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 3em;
  color: #ffffff;
  margin-top: 2px;
  margin-bottom: 0em;
}

.reward-grid {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 4%;
  width: 100%;
  max-width: 1300px;
  padding: 50px 0;
  margin: 0 auto;
  flex-wrap: nowrap;
}

.prize-card {
  background-color: rgba(255, 255, 255, 0.24);
  border-radius: 15px;
  box-shadow: 8px 4px 8px rgba(255, 253, 253, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  font: 600 16px Barlow, sans-serif;
  color: #fff;
  text-align: center;
  padding: 30px;
  height: auto;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  position: relative;
  width: 30%;
  
  
  max-width: none;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  &.is-flipped .prize-card-inner{
    transform: rotateY(180deg);
  }
}
.prize-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.prize-card-front,
.prize-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.prize-card-front{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prize-card-back {
  // background-color: rgba(255, 255, 255, 0.24);
  transform: rotateY(180deg);
  padding: 20px;
  border-radius: 15px;
}

.prize-view-more-btn {
  background-color:#4a7a64 ;
  color: white !important;
  border: 2px solid #4a7a64 !important; // Add a border for visibility
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 1rem auto;
  padding: 10px 20px;
  
  &:hover {
    background-color:#355849 ;
  }
}

.prize-card:nth-child(1) {
  height: 32rem; 
  align-self: flex-end;
  margin-bottom: 18px;
}

.prize-card:nth-child(2) {
  height: 36.6rem; 
  margin-bottom: 0px;

}

.prize-card:nth-child(3) {
  height: 34.3rem; 
  align-self: flex-end; 
  margin-bottom: 14px;
}

.prize-card:hover {
  transform: scale(1.1);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.prize-image {
  aspect-ratio: 1.22;
  object-fit: auto;
  object-position: center;
  width: 219px;
  max-width: 100%;
  transition: filter 0.5s ease;
}

.prize-amount {
  margin-top: 30px;
  font: 64px Barlow, sans-serif;
}

.prize-detail {
  font-family: Barlow, sans-serif;
  text-transform: uppercase;
  margin-top: 10px;
}

.prize-divider {
  align-self: stretch;
  margin-top: 10px;
  height: 1px;
  border: 1px solid #5c977e;
}


@media (max-width: 767px) {
  .prize-section {
    padding: 20px;
    margin-bottom: -3rem;
  }

  .prize-title-container {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .prize-section-title {
    font-size: 2em;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.15);
  }

  .prize-title {
    font-size: 1.9em;
    margin-top: em;
  }

  .prize-category-title {
    font-size: 1.2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .reward-grid {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px 0;
  }

  .prize-card {
    width: 80%;
    max-width: 300px;
    margin-bottom: 0;
    padding: 2px;
    height: auto;
    aspect-ratio: auto;
  }

  .prize-card:nth-child(1),
  .prize-card:nth-child(2),
  .prize-card:nth-child(3) {
    height: 23rem;
    align-self: auto;
    margin-bottom: 0;
  }
  .prize-card:nth-child(2){
    height: 25rem;
  }
  .prize-image {
    width: 140px;
    height: auto;
  }

  .prize-amount {
    font-size: 28px;
    margin: 10px 0;
  }

  .prize-detail {
    font-size: 14px;
    margin: 5px 0;
  }

  .prize-divider {
    width: 80%;
    margin: 10px auto;
  }
}
@media (max-width: 767px) {
  .prize-card-front,
  .prize-card-back {
    padding: 15px;
  }
  
  .view-more-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}
