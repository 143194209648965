.meetup-places-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #021713;
    padding: 2.5em 1.9em;
    position: relative;
    overflow: hidden;
  }
  
  .meetup-places-title-container {
    margin-top: 4em;
    position: relative;
    text-align: center;
    margin-bottom: 3em;
  }
  
  .meetup-places-section-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 6.25em;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1.4px rgba(255, 255, 255, 0.15);
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -58%);
    z-index: 1;
    white-space: nowrap;
  }
  
  .meetup-places-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 4em;
    color: #ffffff;
    z-index: 2;
    position: relative;
    margin-top: 50px;
  }
  
  .meetup-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    width: 100%;
    max-width: 1297px;
    margin-top: 2em;
  }
  
  .meetup-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 1.1rem;
  }
  
  .meetup-card::before,
  .meetup-card::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #021713;
    border-radius: 50%;
    margin-top: 5.5rem;
  }
  
  .meetup-card::before {
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
  }
  
  .meetup-card::after {
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
  }
  
  .meetup-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .meetup-card-city {
    font-family: 'Poppins';
    font-size: 48px;
    font-weight: 600;
    margin: 0;
  }
  
  .meetup-card-type {
    font-family: 'Poppins';
    font-size: 23px;
    // margin-top: 15px;
  }
  
  .meetup-card-price {
    margin-top: 1em;
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 600;
  }
  
  .meetup-card-image {
    text-align: center;
    
    .circle-progress {
        margin-left: auto;
        margin-right: auto;
        width: 10%;
    }
  }
  
  .meetup-card-image img {
    margin-bottom: -1.2rem;
    margin-top: 4rem;
    width: 8rem;
  }
  
  .meetup-card-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .time-line {
    flex-grow: 1;
    height: 2px;
    background: repeating-linear-gradient(to right, #424242 0, #424242 5px, transparent 5px, transparent 10px);
  }
  
  .meetup-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
    // margin-top: 20px;
  }
  
  .meetup-card-venue {
    font-family: 'Inter';
    font-size: 1.04rem;
    font-weight: 400;
    margin-right: 20px;
  }
  
  
  .meetup-card-status {
    background: linear-gradient(90deg, #149087 0%, #0F4945 100%);
    color: #fff;
    border: none;
    padding: 10px 50px;
    // margin-top: -1.4rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background 0.3s ease;
  }
  
  .meetup-card-status:hover {
    background: linear-gradient(90deg, #149087 0%, #0F4945 100%);
  }
  
  .meetup-card-status:active {
    background: linear-gradient(90deg, #149087 0%, #0F4945 100%);
  }
  
  
  @media (max-width: 991px) {
    .meetup-places-title-container {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .meetup-places-section-title {
      font-size: 1.5em;
      margin-top: 5px;
    }
    .meetup-places-title {
      font-size: 1em;
      margin-top: 1.3em;
      text-wrap: nowrap;
    }
    .meetup-cards-container {
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 0 1em;
    }
    .event-description {
      padding-top: 2em;
      padding-bottom: 2em;
    }
    
    // New styles for mobile card view
    .meetup-card {
      padding: 15px;
      border-radius: 7px;
      margin-bottom: 1em;
    }
    
    .meetup-card-header {
      align-items: center;
    }
    
    .meetup-card-city {
      font-size: 20px;
    }
    
    .meetup-card-type {
      font-size: 10px;
    //   margin-top: 5px;
    }
    
    .meetup-card-price {
        margin-top: 0;
        font-size: 14px;
    }
    
    .meetup-card-image img {
      width: 40px;
      margin-top: 15px;
      margin-bottom: -5px;
    }
    
    .meetup-card-time {
      margin: 5px -4px;
      margin-bottom: -0.2rem;
      padding-bottom: -2rem;
    }
    
    .meetup-card-time p {
      font-size: 14px;
    }
    
    .meetup-card-footer {
      margin-top: 10px;
      padding: 0;
    }
    
    .meetup-card-venue {
      font-size: 9px;
    }
    
    .meetup-card-status {
      font-size: 9px;
      padding: 5px 10px;
      margin-top: 0;
    }
    
    .meetup-card::before,
    .meetup-card::after {
      width: 20px;
      height: 20px;
      border-radius: 70%;
      margin-top: 1.7rem;
    }
  }
  
  /* New Styles for Event Description */
  .event-description {
    color: #fff;
    text-align: center;
    font: 300 20px Poppins, sans-serif;
    padding-top: 0em;
    padding-bottom: 0em;
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    @media (max-width:991px) {
      font-size: small;
    }
  }
  
  .event-description p {
    margin-bottom: 2em;
  }
  .event-new {
    margin-bottom: 0;
  }
  
  .event-description .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  