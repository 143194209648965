.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10rem;
    padding: 0;
    margin-top:0rem;
    margin-bottom: 4rem;
    position: relative;
  } 
  .toggle-switch {
    position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  background-color:transparent;
  padding: 10px;
  border-radius: 20px;
  }

  .toggle-option {
    flex: 1;
    padding: 10px 20px;
    background: none;
    border: none;
    color: #ccc;
    border-radius: 15%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &.active {
      background-color: #0A8D81;
      color: #fff;
    }

    &:not(.active):hover {
      background-color: rgba(10, 141, 129, 0.2);
    }
  }
  
  .timeline-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 7em;
  }
  
  .timeline-step {
    display: flex;
    flex-direction: row;
    align-items: flex-start; // Align items at the top to keep cards and lines aligned
    position: relative;
    width: 100%;
    margin-bottom: 4rem; // Space between steps
  }
  
  .timeline-segment {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0; // Position at the top by default
    z-index: -1; // Ensure it doesn't cover the card
  }
  
  .event-card-wrapper {
    display: flex;
    justify-content: center; /* Center the inner card horizontally */
    width: 100%;
  }
  
  .event-card-inner {
    width: 30rem; // Adjust the width of the cards according to the image
  height: 14rem; // Adjust the height based on the image
  border-radius: 16px; // Reduce the border-radius to match the image
  background-color: #2d2e2e; // Retain the background color
  display: flex;
  font-family: Poppins, sans-serif;
  padding: 10px; // Adjust the padding based on the image
  flex-direction: column;
  justify-content: center; // Center content vertically within the card
  }
  
  .position-left {
    justify-content: flex-start;
    margin-right: auto;
    margin-top: 0.5rem;
    padding-top: 0rem;
    margin-bottom: 2rem;
  }
  
  .position-right {
    justify-content: flex-end;
    margin-left: 29rem;
  
    margin-top: -0.5rem;
    margin-bottom: 2rem;
  }
  
  .simple-card-inner {
    align-self: flex-start;
    margin-left: auto;
    text-align: left;
    margin-bottom: 0.5rem;
    margin-top: -1rem; // Align with the timeline segment
    margin-right: 38.5rem; // Push the card to the left
  }
  
  .inverted-card-inner {
    align-self: flex-end;
    text-align: right;
    margin-bottom: 1.5rem;
    margin-top: -1rem; // Align with the timeline segment
    margin-right: auto;
    margin-left: 12rem; // Push the card to the right
  }
  
  .simple-card,
  .inverted-card {
    display: flex;
    flex-direction: column;
  }
  
  .inverted-card {
    margin-top: 1rem; // Adjust margin to align with the SVG line
  }
  
  .event-header {
    text-transform: uppercase;
  
    .event-time {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
    }
  
    .event-title {
      font-size: 32px;
      font-weight: 600;
      background: linear-gradient(99deg, #1c7069 2.01%, #2e8e87 97.99%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  .event-description {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-top: 6px;
    align-items: flex-start;
  }
  
  @media(min-width: 1118px) and (max-width: 1700px) {
    .timeline-container {
      overflow: hidden;
    }
  
    .timeline-content {
      max-width: 100vw;
      margin-top: 8em;
    }
  
    .timeline-step {
      margin-bottom: 2.5rem;
    }
  
    .timeline-segment {
      // top: 20px;
    }
  
    .event-card-inner {
      width: 50%;
      height: 12.5rem;
      padding: 20px;
    }
  
    .position-left {
      margin-left: 36rem;
      text-align: center;
    }
  
    .position-right {
      margin-top: 1rem;
      margin-right: 15rem;
      text-align: center;
    }
  
    .simple-card-inner, .inverted-card-inner {
      margin-right: auto;
      margin-left: auto;
    }
  
    .simple-card-inner {
      width: 45%;
      margin-top: -1rem;
      margin-left: 3rem;
      margin-bottom: 3.5rem;
    }
  
    .inverted-card-inner {
      margin-top: -0.6rem;
      margin-left: 4rem;
      margin-bottom: 1.9rem;
    }
  
    .event-header .event-time {
      // font-size: 10px;
    }
  
    .event-header .event-title {
      // font-size: 14px;
    }
  
    .event-description {
      // font-size: 10px;
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .toggle-switch {
      position: absolute;
      top: 0rem; /* Adjust this value to position the toggle over 14th and 15th Sep */
      right: 0px;
       margin-top: -1rem;
       margin-bottom: 10rem;
      display: flex;
      background-color: transparent;
      padding: 10px;
      border-radius: 20px;
      z-index: 1000; /* Ensure it's above other elements */
    }
    .timeline-container{
      overflow: hidden;
      position: relative;
    }
    .timeline-content{
      max-width: 100vw;
      margin-top: 2em;
    }
    .timeline-step {
      margin-bottom: 2rem;
    }
  
    .timeline-segment {
      top: 20px;
    }
  
    .event-card-inner {
      // width: 90%;
      // height: 8rem;
      width: 50%;
      height: 5.5rem;
      padding: 20px;
    }
  
    .position-left {
      margin-right: 0;
      text-align: center;
      // margin-bottom: 2.7rem;
    }
  
    .position-right {
      margin-left: 0;
      text-align: center;
    }
  
    .simple-card-inner,
    .inverted-card-inner {
      margin-right: auto;
      margin-left: auto;
    }
    .simple-card-inner{
      margin-top: 1rem;
      margin-left: 8rem;
      margin-bottom: -2rem;
    }
    .inverted-card-inner{
      margin-top: 2rem;
      margin-bottom: -1.29rem;
    }
  
    .event-header {
      .event-time {
        font-size: 10px;
      }
  
      .event-title {
        font-size: 14px;
      }
    }
  
    .event-description {
      font-size: 10px;
      text-align: center;
    }
  }  
  
  .htm-timeline-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .htm-timeline-nav button {
    background: none;
    border: none;
    color: #ccc;
    font-size: 1.2em;
    cursor: pointer;
    padding: 10px 20px;
    position: relative; /* For positioning the underline */
    transition: color 0.3s ease;
  }
  
  .htm-timeline-nav button.active,
  .htm-timeline-nav button:hover {
    color: #fff;
  }
  
  .htm-timeline-nav button.active::after,
  .htm-timeline-nav button:hover::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px; /* Position the underline slightly below the text */
    height: 2px;
    background-color: #ffffff; /* White underline */
    border-radius: 2px; /* Rounded ends */
    transition: background-color 0.3s ease;
  }
  
  .htm-timeline-nav button.active::after {
    background-color: #ffffff;
    width: 100%; /* Full width underline for the active button */
  }
  
  .htm-timeline-nav button.hover::after {
    background-color: #ffffff;
  }
  
  .htm-timeline-nav button:not(.active)::after {
    width: 0; /* No underline for inactive buttons */
  }
  
  .htm-timeline-nav button.active {
    font-weight: bold;
    color: #ffffff;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .timeline-nav {
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-top: 5rem;
    }
  
    .timeline-nav button {
      font-size: 0.85rem;
      padding: 8px 16px;
      margin-bottom: 10px;
      
    }
  
    .timeline-nav button.active::after,
    .timeline-nav button:hover::after {
      bottom: -3px; /* Adjust the underline position for smaller screens */
      height: 2px;
    }
  }
  @media (max-width: 768px) {
    .toggle-switch {
      position: absolute;
      top: -0.5rem; /* Adjust the top position to move above the nav buttons */
      right:5.7rem;
      margin-top: 0rem; /* Remove excess margin */
      margin-bottom: 5rem;
      display: flex;
      background-color: transparent;
      padding: 10px;
      border-radius: 20px;
      z-index: 3; /* Ensure it's above other elements */
    }
  
    .timeline-container {
      top:3rem;
      overflow: hidden;
      position: relative;
    }
  
    .timeline-content {
      max-width: 100vw;
      margin-top: 7em;
    }
  
    .timeline-step {
      margin-bottom: 2rem;
    }
  
    .timeline-segment {
      top: 20px;
    }
  
    .event-card-inner {
      width: 50%;
      height: 5.5rem;
      padding: 20px;
    }
  
    .position-left {
      margin-right: 0;
      text-align: center;
    }
  
    .position-right {
      margin-left: 0;
      text-align: center;
    }
  
    .simple-card-inner,
    .inverted-card-inner {
      margin-right: auto;
      margin-left: auto;
    }
  
    .simple-card-inner {
      margin-top: 1rem;
      margin-left: 8rem;
      margin-bottom: -2rem;
    }
  
    .inverted-card-inner {
      margin-top: 2rem;
      margin-bottom: -1.29rem;
    }
  
    .event-header {
      .event-time {
        font-size: 10px;
      }
  
      .event-title {
        font-size: 14px;
      }
    }
  
    .event-description {
      font-size: 10px;
      text-align: center;
    }
  
    .htm-timeline-nav {
      top:5rem;
      flex-direction: row;
      align-items: center;
      margin-top: 6rem; /* Adjust the margin to move the buttons below the toggle */
    }
  
    .htm-timeline-nav button {
      font-size: 0.85rem;
      padding: 8px 16px;
      margin-bottom: 10px;
    }
  
    .htm-timeline-nav button.active::after,
    .htm-timeline-nav button:hover::after {
      bottom: -3px; /* Adjust the underline position for smaller screens */
      height: 2px;
    }
  }
  