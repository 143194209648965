.sponsors-challenges-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #021713;
  padding: 0 1.4em;
  position: relative;
  overflow: hidden;

  .sponsors-challenges-title-container {
    margin-top: 4em;
    position: relative;
    text-align: center;
    margin-bottom: 2em;
  }

  .sponsors-challenges-section-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 6.25rem;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1.4px rgba(255, 255, 255, 0.15);
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -58%);
    z-index: 1;
    transition: all 0.35s ease;
    white-space: nowrap;
    @media(max-width:991px) {
      font-size: 2.3rem;
    }
  }

  .sponsors-challenges-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 4rem;
    color: #ffffff;
    z-index: 2;
    position: relative;
    transition: all 0.35s ease;
    margin-top: 50px;
    @media(max-width:991px) {
      font-size: 1.5rem;
      margin-top: 1.5rem;
      
    }
  }

  .sponsors-challenges-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.5rem;
    width: 100%;
    max-width: 1200px;
    align-items: start;
    margin-bottom: 3rem;
  }

  .sponsor-challenge-card {
    border-radius: 8px;
    background: linear-gradient(99deg, #0d2826 2.01%, #2e8e87 97.99%);
    padding: 20px;
    border: 1px solid #fff;
    margin-bottom: 1rem;
    overflow: hidden;
    
    transition: transform 0.4s ease,box-shadow 0.35s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 10px 20px rgba(1,36,17,0.3);
    }

    .challenge-content {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      height: 100%;
      transition:all 0.35s ease;
    }

    .challenge-info {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      position: relative;
      z-index: 2;
      flex: 1;
      overflow: hidden; /* Prevent overflow */
      justify-content: space-between;

      .challenge-name,
      .challenge-description {
        font-family: Poppins, sans-serif;
        letter-spacing: 0.12px;
        margin: 0;
        transition: all 0.35s ease;
        display: block; /* Ensure block display for text */
        overflow-wrap: break-word; /* Ensure text wraps correctly */
      }

      .challenge-name {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .challenge-description {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .prize-info {
        font-size: 16.5px;
        margin-bottom: 0rem;
        margin-top: -1rem;
        display: flex;
        transition: all 0.35s ease;
        align-items: center;


        .prize-amount {
          color: #32cd32;
          margin-right: 10px;
          font-size: 17px;
          font-weight: 500;
        }

        .prize-amount {
          margin-bottom: 1.7rem;
        }
      }

      .winner-text {
        font-size: 15px;
        align-self: center;
        margin-top: 0.25rem;
        transition: all 0.35s ease;
      }

      .sponsors-view-more-btn {
        border-radius: 5px;
        background-color: #021713;
        padding: 10px 20px;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        border: 1px solid #fbfbfb;
        color: #fff;
        cursor: pointer;
        align-self: start;
        transition: transform 0.35s, box-shadow 0.35s;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 10px 20px rgba(1, 36, 17, 0.5);
        }
        @media (max-width:991px) {
          font-size: 0.75rem;
        }
      }
    }

    .full-text {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.35s ease;
      position: absolute;
      left: 0;
      top: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      white-space: nowrap;
      z-index: 10;
      transform: translateY(5px);
    }

    &:hover .full-text {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    .truncated-text {
      position: relative;
      transition: all 0.35s ease;

      &.truncated {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &.expanded {
        display: block;
        -webkit-line-clamp: unset;
      }
    }

    .challenge-details {
      margin-top: 0;
      padding: 0;
      color: #fff;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      
      
      transition: max-height 0.25s ease, opacity 0.5s ease, padding 0.15s ease;

      &.show {
        padding: 10px 0;
        padding-right: 19%;
        max-height: 500px;
        opacity: 0.7;
      }
      @media (max-width:991px) {
        font-size: 0.75rem;
        
      }
    }

    .challenge-image-container {
      position: relative;
      width: 42%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    
      &::before {
        content: '';
        position: absolute;
        width: 180%;
        height: 300%;
        background-color: #fff;
        border-radius: 50%;
        top: 50%;
        left: 240%;
        transform: translate(-50%, -50%) scaleX(2.5) scaleY(0.7); /* Creates a semi-circle */
        z-index: 1; /* Ensures it is behind the logo */
      }
      
      .challenge-image {
        position: relative;
        max-width: 70%;
        height: auto;
        object-fit: contain;
        margin-left: auto;
        z-index: 2; /* Ensures the image is on top of the semi-circle */
        transition: all 0.35s ease;
      }
    }
  }

  @media (max-width: 768px) {
    .challenge-content {
      flex-direction: column;
    }

    .challenge-info,
    .challenge-image-container {
      width: 100%;
    }

    .challenge-image-container {
      aspect-ratio: 1.5;
      margin-top: 20px;
    }
  }

  .trophy-icon {
    width: auto;
    height: auto;
    margin-right: 7px;
    vertical-align: middle;

    @media (max-width: 991px) {
      width: 2rem;
      height: 3rem;
      margin-left: 0;
      padding-left: 0;
    }
  }

 
  @media (max-width: 991px) {
    .sponsors-challenges-cards {
      grid-template-columns: 1fr;
      gap: 0rem;
    }
  
    .sponsor-challenge-card {
      position: relative;
      padding: 20px;
      margin-bottom: 1.5rem;
      border-radius: 12px;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
  
      .challenge-content {
        flex-direction: column;
        position: relative;
      }
  
      .challenge-info {
        font-size: 1rem;
        font-weight: 600;
        width: 70%;
        position: relative;
        z-index: 2;
      }
  
      .challenge-image-container {
        position: absolute;
        right: 0;
        width: 30%;
        background-color: transparent;
        box-shadow: none;
        height: auto;
        z-index: 1;
        opacity: 0.97;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95%;
        top: 40%;
        transform: translateY(-50%);
      }
  
      .challenge-image {
        width: 100%;
        height: 100%;
        max-height: fit-content;
        object-fit: contain;
        opacity: 1;
      }
  
      .challenge-name {
        font-size: 1.2rem !important;
        margin-bottom: 8px;
        font-weight: 700;
      }
  
      .challenge-description {
        font-size: 0.75rem !important;
        font-weight: 350;
        margin-bottom: 12px;
      }
  
      .prize-info {
        display: flex;
        align-items: center;
        margin-bottom: -1rem;
        white-space: nowrap;
  
        .prize-amount {
          font-size: 0.7rem !important;
          font-weight: 500;
          white-space: normal; /* Allows the text to wrap */
  word-wrap: break-word;
          margin-right: 0px;
        }
  
        .prize-amount {
          margin-top: 2rem;
        }
      }
  
      .winner-text {
        font-size: 0.7rem !important;
        margin-left: 0rem;
        padding-top: 0.2rem;
        
        z-index: 3; /* Ensures it's on top of other elements */
        display: inline-block;
        vertical-align: middle;
      }
  
      .sponsors-view-more-btn {
        margin: 0 auto;
        display: block;
        font-size: 0.67rem;
        align-self: center;
      }
  
      .trophy-icon {
        width: 2.9rem;
        height: 2rem;
        margin-right: -0rem;
        margin-left: -0.7rem;
      }
  
      
    }
  }
  
}
.challenge-type-title {
  font-family: 'Poppins', sans-serif; /* Choose the font-family that matches your design */
  font-size: 2.5rem; /* Adjust the font size */
  font-weight: 400; /* Make the text bold */
  color: #faf8f8; /* Set the text color */
  margin-bottom: 1rem; /* Add space below the title */
  margin-top: -1rem;
  text-transform: uppercase; /* Transform text to uppercase for emphasis */
  text-align: center; /* Center align the title */
  letter-spacing: 0.05rem; /* Add spacing between letters for better readability */
  opacity: 0.9;
  /* Responsive adjustments */
  @media (max-width: 768px) {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
    margin-bottom: 0.75rem; /* Adjust margin for smaller screens */
  }
}
