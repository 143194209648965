.htm-sponsors-sponsors-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #021713;
    padding: 1rem 1.5em;
    margin-top: -1rem;
    overflow-x: hidden;
  }
  
  .htm-sponsors-title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 4em;
    color: #ffffff;
    z-index: 2;
    position: relative;
    margin-top: 0px;
  }
  
  .htm-sponsors-sponsor-category {
    margin-bottom: 2em;
  }
  
  .htm-sponsors-league-partner-title,
  .htm-sponsors-diamond-title,
  .htm-sponsors-gold-title,
  .htm-sponsors-bronze-title,
  .htm-sponsors-blockchain-partner-title,
  .htm-sponsors-general-sponsors-title,
  .htm-sponsors-community-partners-title ,
  .htm-sponsors-certificate-partners-title,
  .htm-sponsors-incubation-partners-title,
  .htm-sponsors-platform-partners-title,
  .htm-sponsors-knowledge-partners-title {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin: 20px 0;
    text-align: center;
  }
  
  .htm-sponsors-league-partner-title,
  .htm-sponsors-diamond-title,
  .htm-sponsors-bronze-title,
  .htm-sponsors-gold-title,
  .htm-sponsors-blockchain-partner-title,
  .htm-sponsors-certificate-partners-title,
  .htm-sponsors-incubation-partners-title,
  .htm-sponsors-platform-partners-title ,
  .htm-sponsors-knowledge-partners-title{
    font-size: 4rem;
    color: #d3d3d3;
  }
  
  .htm-sponsors-general-sponsors-title {
    font-size: 4rem;
    color: #cd7f32;
  }
  
  .htm-sponsors-community-partners-title {
    font-size: 4rem;
    color: #cd7f32;
  }
  .htm-sponsors-certificate-partners-title {
    font-size: 4rem;
    color: #acaa50; // Dark green color, you can customize this as needed
  }
  .htm-sponsors-platform-partners-title{
    font-size: 4rem;
    color:#0f579b
  }
  .htm-sponsors-incubation-partners-title{
    font-size: 4rem;
    color:#16325B;
  }
  

  .htm-sponsors-sponsor-logos {
    display: grid;
    gap: 3em;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .htm-sponsors-sponsor-logo {
    width: 15em;
    height: 6em;
    background-color: #FCF8F3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      max-width: 60%;
      max-height: 80%;
    }
  }
  
  .htm-sponsors-league-partner-logos,
  .htm-sponsors-diamond-logos,
  .htm-sponsors-gold-logos,
  .htm-sponsors-bronze-logos,
  .htm-sponsors-blockchain-partner-logos,
  .htm-sponsors-certificate-partners-logos,
  .htm-sponsors-platform-partners-logos,
  .htm-sponsors-knowledge-partners-logos{
    grid-template-columns: repeat(1, 1fr); // 1 column for single sponsors
    justify-items: center;
  } 
  
  .htm-sponsors-gold-logos{
    grid-template-columns: repeat(2,1fr);
   justify-content: center;
  //  gap:0;
   padding: 0 10em;
   justify-items: center;
    img{
      max-width: 85%;
    }
  }
  
 
  .htm-sponsors-bronze-logos{
    grid-template-columns: (1,1fr);
    
  }
  .htm-sponsors-certificate-partners-logos {
    grid-template-columns: repeat(1, 1fr); // Adjust columns as needed
    justify-items: center; 
  }
  .htm-sponsors-platform-partners-logos{
    grid-template-columns: repeat(1, 1fr); 
    justify-items: center;
  }
  .htm-sponsors-incubation-partners-logos{
    grid-template-columns: repeat(1, 1fr); 
    justify-items: center;
  }

  .htm-sponsors-general-sponsors-logos {
    grid-template-columns: repeat(4, 1fr); // 4 columns for General Sponsors
  }
  
  .htm-sponsors-community-partners-logos,
  .htm-sponsors-incubation-partners-logos
   {
    gap: 2.5rem;
    grid-template-columns: repeat(4, 1fr); // 2 columns for Community Partners
    justify-items: center;
    margin: 0 10em;
    @media (max-width: 991px) {
      margin: 0 3em;
    }
  }
  
  /* Mobile View */
  @media (max-width: 991px) {
    .htm-sponsors-title {
      font-size: 1em;
    }
  
    .htm-sponsors-league-partner-title,
    .htm-sponsors-diamond-title,
    .htm-sponsors-gold-title,
    .htm-sponsors-bronze-title,
    .htm-sponsors-blockchain-partner-title,
    .htm-sponsors-general-sponsors-title,
    .htm-sponsors-community-partners-title,
    .htm-sponsors-certificate-partners-title,
    .htm-sponsors-incubation-partners-title,
    .htm-sponsors-platform-partners-title ,
    .htm-sponsors-knowledge-partners-title{
      font-size: 1em;
      margin-left: 0; // Center align titles on mobile
      margin-bottom: 0.5em; // Minimize space below titles
    }
  
    .htm-sponsors-sponsor-logos {
      gap: 1em;
      margin-bottom: 20px;
    }
  
    .htm-sponsors-sponsor-logo {
      width: 8em;
      height: 4em;
      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
  
    .htm-sponsors-league-partner-logos,
    .htm-sponsors-diamond-logos,
    .htm-sponsors-gold-logos,
    .htm-sponsors-bronze-logos,
    .htm-sponsors-blockchain-partner-logos,
    .htm-sponsors-certificate-partners-logos,
    .htm-sponsors-platform-partners-logos,
    .htm-sponsors-knowledge-partners-logos{
      grid-template-columns: repeat(1, 1fr); // 1 column for single sponsors
      margin-left: 0; // Center align logos on mobile
    }
    .htm-sponsors-gold-logos{
      grid-template-columns: repeat(2,1fr);
    }
  
    .htm-sponsors-general-sponsors-logos {
      grid-template-columns: repeat(2, 1fr); // Reduce to 2 columns for General Sponsors
    }
  
    .htm-sponsors-community-partners-logos,
    .htm-sponsors-incubation-partners-logos {
      grid-template-columns: repeat(1, 1fr); // Reduce to 1 column for Community Partners
    }
  }
  
  @media (max-width: 767px) {
    .htm-sponsors-sponsors-container {
      padding: 0.25rem 0.5em; /* Minimize padding around the container */
    }
    
    .htm-sponsors-title {
      font-size: 1.5em; // Further reduce title font size
      text-align: center; // Center-align title text
    }
  
    .htm-sponsors-sponsor-category {
      margin-bottom: 1em; // Minimize margin between categories
      text-align: center; // Center-align category content
    }
  
    .htm-sponsors-title-container,
    .htm-sponsors-section-title,
    .htm-sponsors-title {
      font-size: 1.2em; // Reduce title font size further for mobile
      margin-left: 0; // Center align titles on mobile
      margin-bottom: 0.5em; // Minimize space below titles
      text-align: center; // Center-align title text
    }
  
    .htm-sponsors-sponsor-logos {
      gap: 0.5em; // Reduce gap between logos
      margin-bottom: 1em; // Minimize margin below logo grid
    }
  
    .htm-sponsors-sponsor-logo {
      width: 6em; // Further reduce logo box size
      height: 2.6em;
      background-color: #FCF8F3; // Keep light background for logos
      border-radius: 20px; // Maintain rounded corners but smaller
  
      img {
        max-width: 70%; // Scale down logo within the box
        max-height: 70%;
      }
    }
    


    .htm-sponsors-league-partner-logos,
    .htm-sponsors-diamond-logos,
    .htm-sponsors-gold-logos,
    .htm-sponsors-bronze-logos,
    .htm-sponsors-blockchain-partner-logos ,
    .htm-sponsors-certificate-partners-logos,
    .htm-sponsors-platform-partners-logos,
    .htm-sponsors-knowledge-partners-logos{
      grid-template-columns: repeat(1, 1fr); // 1 column for single sponsors
    }
  
    .htm-sponsors-general-sponsors-logos {
      grid-template-columns: repeat(3, 1fr); // Consistent 2 columns
      gap: 1rem; // Set a standard gap
      justify-items: center; // Center-align logos
      padding: 0 10em;
    }
  .htm-sponsors-gold-logos{
    grid-template-columns: repeat(2,1fr);
    justify-items: center;
    padding: 0 12.9rem;
  }
    .htm-sponsors-community-partners-logos,
    .htm-sponsors-incubation-partners-logos {
      grid-template-columns: repeat(3, 1fr); // 2 columns for Community Partners
      gap: 1rem; // Standard gap
      padding: 0 7em;
      justify-items: center; // Center-align logos
    }

  }
  .htm-sponsors-gold-title {
    background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .htm-sponsors-diamond-title {
    color: #d3d3d3;
  }
  
  .htm-sponsors-league-partner-title {
    color: #238797;
  }

  .htm-sponsors-bronze-title {
    color: #a04714;
  }
  .htm-sponsors-platform-partners-title{
    color:#5c3786;
  }

  .htm-sponsors-incubation-partners-title{
    color:#0e750a;
  }
  .htm-sponsors-knowledge-partners-title{
    color:#bd3a31
  }
  
  .htm-sponsors-community-partners-title {
    color: #259472;
  
  }
  