.judges-cards {
    background-color: #021713;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;

    
  }
  
  .judges-content {
    width: 100%;
    padding: 5em 15em;
    position: relative;
    z-index: 1;
    margin-bottom: 2em;
  
    @media (max-width: 991px) {
      margin-top: -2rem;
      padding: 1em 2em;
    }
  }
  
  .judges-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5em;
    margin: 5em 0;
  }
  
  .judges-card {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    width: 75%;
    height: auto;
  
    @media (max-width: 991px) {
      border: none;
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  
    &:hover {
      transform: scale(1.1);
    }
  
    &:hover .judges-link1 {
      opacity: 1;
      visibility: visible;
    }
    
    &:hover .judges-link2 {
      opacity: 1;
      visibility: visible;
    }
    
    &:hover .judges-icon {
      opacity: 0.5;
    }
  }
  
  .judges-icon {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 1em;
    z-index: 1;
    transition: opacity 0.3s ease;
  }
  
  .judges-card-title {
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    margin-bottom: 0.5em;
    // text-wrap: nowrap;
  
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
  
  .judges-link1 {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 2;
  
    &:hover {
      opacity: 0.8;
    }
  
    @media (max-width: 768px) {
      font-size: 14px;
      left: 40%;
    }
  }
  
  .judges-link2 {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: white;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 2;
  
    &:hover {
      opacity: 0.8;
    }
  
    @media (max-width: 768px) {
      font-size: 14px;
      left: 60%;
    }
  }
  
  @media (max-width: 1024px) {
    .judges-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .judges-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px; /* Reduce gap between cards */
    }
  }
  
  @media (max-width: 480px) {
    .judges-grid {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
    }
  }
  .timeline-nav {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    margin-bottom: -2rem;
    position: relative;
    padding-bottom: 10px;
  }
  
  .timeline-nav::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    opacity: 0.3;
    background-color: #ccc; /* Color of the line spanning across all buttons */
  }
  
  .timeline-nav button {
    background: none;
    border: none;
    color: #666; /* Inactive text color */
    font-size: 1.4rem;
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    transition: color 0.3s ease;
    margin: 0 20px; /* Adjust spacing between buttons */
  }
  
  .timeline-nav button.active,
  .timeline-nav button:hover {
    color: #fff; /* Highlighted text color */
  }
  
  .timeline-nav button.active::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Align underline with the spanning line */
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ffffff; /* Underline color */
    transition: width 0.3s ease;
    width: 100%;
  }
  
  .timeline-nav button::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Same bottom alignment for inactive */
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: width 0.3s ease;
  }
  
  .timeline-nav button.active::after {
    background-color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .timeline-nav {
      flex-direction: row;
      align-items: center;
      margin: 0;
      margin-bottom: auto;
    }
  
    .timeline-nav button {
      font-size: 0.85rem;
      padding: 8px 16px;
      margin-bottom: 10px;
    }
  
    .timeline-nav button.active::after,
    .timeline-nav button:hover::after {
      bottom: -19px;
      height: 2px;
    }
  }